import React from 'react';
import * as Styled from './slpBlueBackgroundImageStyles';

const SlpBlueBackgroundImage = ({ sectionData }) => {
  return (
    <React.Fragment>
      <Styled.BlueContainer>
        <Styled.ImageStyle
          src={sectionData?.image?.file?.url}
          alt={sectionData?.image?.title}
        ></Styled.ImageStyle>
      </Styled.BlueContainer>
    </React.Fragment>
  );
};
export default SlpBlueBackgroundImage;
